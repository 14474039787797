/* styles.css */
[data-doorman] {
    visibility: hidden;
  }
   
/* Global CSS file */
body {
  font-family: Futura, sans-serif; /* Apply Futura font globally */
}

/* Global CSS file */
.futura-font {
  font-family: Futura, sans-serif; /* Apply Futura font to elements with this class */
}

/* styles.css */
.card {
  backdrop-filter: blur(5px) saturate(75%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: white;
  box-shadow: 3px -1px 32px 0 rgb(165 165 165 / 72%);
}

h1{
  font-size: 22px;
  letter-spacing: 0.2em;
  font-weight: 400;
}

p{
  font-family: Futura, sans-serif;
  font-size: 14px;
  color:#1c1b1b;
}

.landing-section{
  position: relative;
  height: 90vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}

.leftButton{
  width: auto;
  height: 40px;
  margin-right: 20px!important;
  border-radius: 0!important;
  background-color: white!important;
  color: #363636;
}

.righButton{
  width: auto; 
  height: 40px;
  border-radius: 0!important;
  background-color: white!important;
  color: #363636;
}

.servicePageButton{
  width: auto;
  height: 40px;
  margin-right: 20px!important;
  border-radius: 0!important;
  background-color: black!important;
}

.landingButtonsTypography{
  text-transform: uppercase!important;
  padding: 14px 18px!important;
  font-size: 12px!important;
  text-align: center!important;
  letter-spacing: 0.2em!important;
  font-family: Futura, sans-serif!important;
  font-weight: 400!important;
  font-style: normal!important;
}

.content-section{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  background-size: cover;
  background-position :center;
}

.content-card{
  max-width: 520px;
  padding: 48px 15px;
  background-color : white;
  text-align: center;
}

.section-fifth{
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10% 0px 10%;
  border-bottom: 0.5px solid #6a685f6b;  
}

.typography-center{
  text-align: center;
}

.policies_links{
  font-family: Futura, sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  color: #454545;
  background: #fff;
  line-height: 1.65;
}

.content_panel{
  padding: 0px 240px;
}

.accordion_widget_title{
  font-size: 14px;
  font-family: Futura, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #454545;
  line-height: 1.65;
  margin-bottom: 12px;
}

.accordion_title{
      margin-bottom: 34px;
      font-size: 20px;
      font-family: Futura, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #454545;
    -webkit-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
    letter-spacing: .2em;
    text-transform: uppercase;
}
.accordion_sub_title{
  margin: 12px 0;
  font-family: Futura, sans-serif;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: #454545;
  -webkit-transition: color .2s ease-in-out;
  transition: color .2s ease-in-out;
  letter-spacing: 0em;
}

.accordion_sub-description{
  font-family: Futura, sans-serif;
    font-weight: 100;
    font-style: normal;
    color: #454545;
    background: #fff;
    line-height: 1.65;
    font-size: 14px;
    letter-spacing: 0.001em;
    margin-Left:22px;
}

.services_content{
  margin: 35px auto;
  max-width: 1000px;
}

/* styles.css */

/* Add this media query for smaller screens */
@media (max-width: 768px) {
  .content_panel{
    padding: 0px 10px;
  }
  section {
    height: auto; /* Change height to auto for smaller screens */
  }

  .heading-mobile{
    padding: 0px 50px!important;
  }

  .button-container {
    flex-direction: column; /* Change flex-direction to column for smaller screens */
    margin-top: 20px; /* Add margin-top for spacing */
    align-items: center;
  }

  .leftButton, .righButton{
    margin-right: 0px!important;
    width: 245px!important;
  }

  .button-container button {
    margin-bottom: 10px; /* Add margin-bottom for spacing between buttons */
  }

  .card{
    margin: auto 30px;
  }
  .card p{
    font-size: 12px;
  }
  .section-fourth{
    padding: 35px 10% 86px!important;
  }
  .fourthImage{
    max-width: 100%!important;
    height: 300px!important;
  }
  .accordion_widget{
    display: none;
  }
  .accordion_body{
    margin-left: 0px!important;
    padding:0px 5px!important;
  }
  .accordion_body_image{
    max-width: 100%!important;
  }
}

@media screen and (min-width: 641px){
  .section-back {
      min-height: 480px!important;
  }
  }

@media screen and (min-width: 1240px){
  .section-back {
    min-height: 600px!important;
}
}





.accordion {
  display: flex;
}

.accordion-titles {
  width: 200px;
  background-color: #f0f0f0;
}

.accordion-title {
  padding: 10px;
  cursor: pointer;
}

.accordion-title.active {
  background-color: #e0e0e0;
}

.accordion-contents {
  flex: 1;
  padding: 10px;
}

.content-item {
  margin-bottom: 20px;
}
